var animationLength = 300; // Change this in _grensesnitt-lightbox.scss as well if changed
var $grensesnittLightboxContainer = $('.gl-container');
var $lightboxes = $('.lightbox');
const galleries = document.querySelectorAll('.image-gallery');

    $lightboxes.click(function (e) {
        e.preventDefault();
        if(!this.parentNode.classList.contains('active')) {
            showLightbox();
        }
        addImages(this)

        $grensesnittLightboxContainer[0].addEventListener('touchmove', function (e) {
            e.preventDefault();
        });
    });

    $('.gl-container .gl-close').click(function (e) {
        e.preventDefault();      
        hideLightbox();
    });

    function addImages(current) {
        var $imageContainer = $('.gl-image');
        $imageContainer.html('');

        if (current.tagName === 'IMG') {
            $imageContainer.html('');
            var url = current.getAttribute('src');
            $imageContainer.append('<img src="' + url + '" />');
        }
    }

    function hideLightbox() {
        $grensesnittLightboxContainer.removeClass('fade');
        $('header').removeClass('lightbox-open');

        setTimeout(function () {
            $grensesnittLightboxContainer.removeClass('show');
            $('body').removeClass('no-scroll');
        }, animationLength);
    }

    function showLightbox() {
        $grensesnittLightboxContainer.addClass('show');

        setTimeout(function () {
            $grensesnittLightboxContainer.addClass('fade');
            $('header').addClass('lightbox-open');

            setTimeout(function () {
                $('body').addClass('no-scroll');
            }, animationLength);
        }, 10);
    }

if(galleries.length > 0) {
    galleries.forEach((gallery) => {
        let imageContainer = gallery.querySelector('.wrapper');
        
        const preventClick = (e) => {
            e.preventDefault();
            e.stopImmediatePropagation();
        }

        let isDown = false;
        var isDragged = false;
        let startX;
        let startY;
        let scrollLeft;
        let scrollTop;
        let velX;
        let velY;

        imageContainer.addEventListener('mousedown', (e) => {
            isDown = true;
            setTimeout(function() {
                imageContainer.classList.add('active');
            }, 100);
            startX = e.pageX - imageContainer.offsetLeft;
            scrollLeft = imageContainer.scrollLeft;
            startY = e.pageY - imageContainer.offsetTop;
            scrollTop = imageContainer.scrollTop;
            cancelXMomentumTracking();
            cancelYMomentumTracking();
        });
        imageContainer.addEventListener('mouseleave', () => {
            isDown = false;
            setTimeout(function() {
                imageContainer.classList.remove('active');
            }, 50);
        });
        imageContainer.addEventListener('mouseup', () => {
            isDown = false;
            let elements = gallery.querySelectorAll('.lightbox');
            if(isDragged){
                for(let i = 0; i<elements.length; i++){
                    elements[i].addEventListener("click", preventClick);
                }
            }else{
                for(let i = 0; i<elements.length; i++){
                    elements[i].removeEventListener("click", preventClick);
                }
            }
            setTimeout(function() {
                imageContainer.classList.remove('active');
            }, 50);
            isDragged = false;
                    // Start a frame loop to continue drag momentum 
                    beginXMomentumTracking();
                    beginYMomentumTracking();
        });
        imageContainer.addEventListener('mousemove', (e) => {
            if(!isDown) return;
            isDragged = true;
            e.preventDefault();
            const x = e.pageX - imageContainer.offsetLeft;
            const y = e.pageY - imageContainer.offsetTop;
            const walkX = (x - startX) * 1.5;
            const walkY = (y - startY) * 1.5;

            // Store the previous scroll positions
            var prevScrollLeft = imageContainer.scrollLeft; 
            var prevScrollTop = imageContainer.scrollTop; 
            imageContainer.scrollLeft = scrollLeft - walkX;
            imageContainer.scrollTop = scrollTop - walkY;

            // Compare change in position to work out drag speed 
            velX = imageContainer.scrollLeft - prevScrollLeft; 
            velY = imageContainer.scrollTop - prevScrollTop;
        });

        let xMomentumID;
        function beginXMomentumTracking(){
            cancelXMomentumTracking();
            xMomentumID = requestAnimationFrame(xMomentumLoop); 
        }
        function cancelXMomentumTracking(){
            cancelAnimationFrame(xMomentumID);
        }
        function xMomentumLoop(){
            if(Math.abs(velX) > 0.5) {
                imageContainer.scrollLeft += velX; // Apply the velocity to the scroll position
                velX *= 0.9; // Slow the velocity slightly
                xMomentumID = requestAnimationFrame(xMomentumLoop); // Keep looping 
            }
        }

        let yMomentumID;
        function beginYMomentumTracking(){
            cancelYMomentumTracking();
            yMomentumID = requestAnimationFrame(yMomentumLoop); 
        }
        function cancelYMomentumTracking(){
            cancelAnimationFrame(yMomentumID);
        }
        function yMomentumLoop(){
            if(Math.abs(velY) > 0.5) {
                imageContainer.scrollTop += velY; // Apply the velocity to the scroll position
                velY *= 0.9; // Slow the velocity slightly
                yMomentumID = requestAnimationFrame(yMomentumLoop); // Keep looping 
            }
        }
    });
}