export const intersectionObserve = function(elements, rootMargin, threshold, onIntersectionChange) {
    if ('IntersectionObserver' in window) {

    var options = {
        root: null, // relative to document viewport
        rootMargin: '0px 0px 0px', // margin around root. Values are similar to css property. Unitless values not allowed
        threshold: 0.1 // visible amount of item shown in relation to root
    };

    var onIntersectionChange = function (changes, observer) {
        changes.forEach(change => {
            if (change.isIntersecting) {
                loadTarget(change.target);
            }
        });
    };

    var observer = new IntersectionObserver(onIntersectionChange, options);

    function setup() {
        elements.forEach(element => observer.observe(element));
    }

    setup();

    setTimeout(setup, 1000);
    setTimeout(setup, 3000);
} else {
    var lazyElements = document.getElementsByClassName('lazy');
    for (var i = 0; i < lazyElements.length; i++) {
        var element = lazyElements[i];
        loadTarget(element);
    }
}

function loadTarget(target) {
    if (target.classList && target.classList.contains('loaded')) {
        return;
    }

    if (target.tagName === 'IMG') {
        if(document.querySelector('.properties .tiles').classList.contains('active')) {
            setTimeout(function() {
                target.onload = function() {
                    showElement(target);
                }
        
                target.src = target.getAttribute('data-src');
                target.classList.add('loaded');
            }, 1000);
        }
        else {
            target.onload = function() {
                showElement(target);
            }
    
            target.src = target.getAttribute('data-src');
            target.classList.add('loaded');
        }
    }
}

function showElement(element) {
    if (element.classList) {
        if (!element.classList.contains('show')) {
            element.classList.add('show');
        }
    } else {
        element.className += ' show';
    }
}
};

let images = document.querySelectorAll('img.lazy');
intersectionObserve(images, 0, 0, (image, intersectionRatio) => {
    (function(el) {
        if (intersectionRatio > 0 && !el.classList.contains('show')) {
            el.classList.add('show');
        } 
    })(element);
});