import { intersectionObserve } from "./_lazy-image-video";
import { initMap } from "./_map";

const header = document.querySelector('header');
const footer = document.querySelector('footer');
const properties = document.querySelectorAll('.properties .list-properties .tease-property');
const displayFilter = document.querySelector('header .display-filter');
const propertySorting = document.querySelector('.properties .property-sorting');

function propertyEvents() {
    document.querySelectorAll('.properties .tiles .tease-property').forEach((item) => {
        let indicator = item.querySelector('.indicator-btn');
        let overlay = item.querySelector('.overlay');
        let videoContainer = item.querySelector('video')
        let videoURL = item.getAttribute('data-video');
        let isPlaying;
        item.addEventListener('mouseenter', (e) => {
            overlay.classList.add('show');
            if(indicator) {
                indicator.classList.add('show');
            }
            if(videoURL != "") {
                videoContainer.classList.add('show');
                videoContainer.src = videoURL;
                videoContainer.load();
                isPlaying = videoContainer.currentTime > 0 && !videoContainer.paused && !videoContainer.ended && videoContainer.readyState > videoContainer.HAVE_CURRENT_DATA;
                if (!isPlaying) {
                    videoContainer.play();
                }
            }
        });
        item.addEventListener('mouseleave', (e) => {
            overlay.classList.remove('show');
            if(indicator) {
                indicator.classList.remove('show');
            }
            if(videoURL != "") {
                videoContainer.classList.remove('show');
                videoContainer.pause();
                videoContainer.removeAttribute('src');
                videoContainer.load();
            }
        });
    });
}

if(properties.length > 0) {
    propertyEvents();

    // Property metadata
    let newArray = [];
    let propArray = Array.from(properties);
    propArray.forEach((item) => {
        let propObject = item;
        let propAddress = item.getAttribute('data-address');
        let propAreal = item.getAttribute('data-areal');
        let propType = item.getAttribute('data-type');
        let propVacant = item.getAttribute('data-vacant');
        newArray.push({ propObject, propAddress, propAreal, propType, propVacant });
    });

    // Display filter click event
    const leftContent = document.querySelector('.properties .left-content');

    displayFilter.querySelectorAll('a').forEach((item) => {
        item.addEventListener('click', (e) => {
            e.preventDefault();
            window.scrollTo(0,0);
            let slug = item.getAttribute('data-slug');
            displayFilter.querySelector('a.selected').classList.remove('selected');
            item.classList.add('selected');
            document.querySelector('section.properties').classList.remove('list-display');
            document.querySelector('section.properties').classList.remove('map-display');
            footer.style.display = 'none';
            header.querySelector('.logo').classList.remove('mini');
            setTimeout(function() {
                header.querySelector('.logo').classList.remove('anim');
            }, 400);
            if(slug == 'liste') {
                if(window.innerWidth >= 1400) {
                    setTimeout(function() {
                        document.querySelector('#content-section').style.marginTop = 211 + 'px';
                    }, 55);
                }
                else if(window.innerWidth >= 900) {
                    setTimeout(function() {
                        document.querySelector('#content-section').style.marginTop = 230 + 'px';
                        document.querySelector('#content-section').style.marginTop = header.offsetHeight + 'px';
                    }, 50);
                }
                document.querySelector('.filters').classList.remove('sticky');
                header.classList.remove('sticky');
                header.classList.remove('min-header');

                setImage();
                document.querySelector('section.properties').classList.add('list-display');
                let selectedType = header.querySelector('.property-filter .select-input .select-options li.selected a').getAttribute('data-slug');
                if(selectedType != 'alle') {
                    properties.forEach((prop) => {
                        if(!prop.classList.contains(selectedType)) {
                            prop.classList.add('hide');
                        }
                    });
                }
                document.querySelector('.properties .property-sorting a.active').classList.remove('desc');
                document.querySelector('.properties .property-sorting a.active').classList.remove('asc');
                document.querySelector('.properties .property-sorting a.active').classList.remove('active');
                document.querySelector('.properties .property-sorting a.free-space').classList.add('active');
                document.querySelector('.properties .property-sorting a.free-space').classList.add('desc');
                let sortedArray = [...newArray].sort((a, b) => a['propVacant'] - b['propVacant']);
                let resultsArray = sortedArray.map(p => p.propObject).reverse();
                resultsArray.forEach((item) => {
                    document.querySelector('.properties-wrapper .list-properties').appendChild(item);
                });

                let listDisplay = document.querySelector('.properties.list-display');
                let listOffset = listDisplay.querySelector('.wrapper').getBoundingClientRect().top;
                window.addEventListener('scroll', function() {
                    if(listDisplay != undefined) {
                        if((scrollY > (listOffset - header.offsetHeight)) && window.innerWidth >= 1400) {
                            listDisplay.querySelector('.left-content .image').style.top = (scrollY - listOffset + header.offsetHeight) + 'px';
                            listDisplay.querySelector('.left-content .image').style.height = 'calc(100% - ' + (scrollY - listOffset + header.offsetHeight) + 'px)';
                        }
                        else if(window.innerWidth >= 1400) {
                            listDisplay.querySelector('.left-content .image').style.top = 0 + 'px';
                            listDisplay.querySelector('.left-content .image').style.height = '100%';
                        }
                    }
                });

                if(window.innerWidth < 600) {
                    footer.style.display = 'block';
                }
                else {
                    footer.style.display = 'flex';
                }
            }
            else if(slug == 'kart') {
                if(window.innerWidth >= 1400) {
                    setTimeout(function() {
                        document.querySelector('#content-section').style.marginTop = 211 + 'px';
                    }, 55);                
                }
                else if(window.innerWidth >= 900) {
                    setTimeout(function() {
                        document.querySelector('#content-section').style.marginTop = 230 + 'px';
                        document.querySelector('#content-section').style.marginTop = header.offsetHeight + 'px';
                    }, 50);
                }
                document.querySelector('.filters').classList.remove('sticky');
                header.classList.remove('sticky');
                header.classList.remove('min-header');

                document.querySelector('section.properties').classList.add('map-display');
                initMap();
            }
            else {
                header.classList.remove('min-header');
                header.classList.remove('animate');
                let selectedType = header.querySelector('.property-filter .select-input .select-options li.selected a').getAttribute('data-slug');
                let selectedProps;
                if(selectedType == 'alle') {
                    selectedProps = properties;
                }
                else {
                    selectedProps = document.querySelectorAll('.properties .tease-property.' + selectedType);
                    selectedProps.forEach((property) => {
                        property.style.display = 'block';
                        property.classList.add('displayed');
                    });
                }
            }
        });
    });

    // Metadata sorting
    propertySorting.querySelectorAll('a').forEach((item) => {
        item.addEventListener('click', (e) => {
            e.preventDefault();
            let slug = item.getAttribute('data-slug');
            let sortedArray;
            if(slug == 'propAreal' || slug == 'propVacant') {
                sortedArray = [...newArray].sort((a, b) => a[slug] - b[slug]);
            }
            else {
                sortedArray = [...newArray].sort((a, b) => a[slug].localeCompare(b[slug]));
            }
            let resultsArray;
            if(item.classList.contains('active') && item.classList.contains('asc')) {
                item.classList.remove('asc');
                item.classList.add('desc');
                resultsArray = sortedArray.map(p => p.propObject).reverse();
            }
            else if(item.classList.contains('active') && item.classList.contains('desc')) {
                item.classList.remove('desc');
                item.classList.add('asc'); 
                resultsArray = sortedArray.map(p => p.propObject);
            }
            else {
                document.querySelector('.properties .property-sorting a.active').classList.remove('desc');
                document.querySelector('.properties .property-sorting a.active').classList.remove('asc');
                document.querySelector('.properties .property-sorting a.active').classList.remove('active');
                item.classList.add('active');
                item.classList.add('asc');
                resultsArray = sortedArray.map(p => p.propObject);
            }
            resultsArray.forEach((item) => {
                document.querySelector('.properties-wrapper .list-properties').appendChild(item);
            });
        });
    });

    // List display hover function
    function setImage() {
        properties.forEach((property) => {
            let propImageThumbnail = property.getAttribute('data-src');
            let propImageThumbnailMobile = property.getAttribute('data-mobile-src');
            let propImageSquare = property.getAttribute('data-square');
            let propImageLandscape = property.getAttribute('data-landscape');
            let propImages = [propImageThumbnail, propImageThumbnailMobile, propImageSquare, propImageLandscape];
            let randomImage = propImages[Math.floor((Math.random()*propImages.length))];
            property.addEventListener('mouseenter', (e) => {
                leftContent.querySelector('.image').style.backgroundImage = "url('" + randomImage + "')";
            });
            property.addEventListener('mouseleave', (e) => {
                leftContent.querySelector('.image').style.backgroundImage = "unset";
            });
        });
    }

    function filterProperties(searchText) {
        let shownProperties = document.querySelectorAll('.properties .tease-property');
        let selectedType = header.querySelector('.property-filter .select-input .select-options li.selected a').getAttribute('data-slug');
        shownProperties.forEach((property) => {
            property.style.display = 'none';
            property.classList.remove('displayed');
        });
        if(selectedType != 'alle') {
            shownProperties = document.querySelectorAll('.properties .tease-property.' + selectedType);
        }
        shownProperties.forEach((property) => {
            let address = property.getAttribute('data-address');
            if(searchText != '') {
                property.style.display = 'none';
                property.classList.remove('displayed');
                if (address.toLowerCase().indexOf(searchText) !== -1) {
                    property.style.display = 'block';
                    property.classList.add('displayed');
                }
            }
            else {
                property.style.display = 'block';
                property.classList.add('displayed');
            }
        });
    }

    const searchInput = document.querySelector('#properties-search');
    searchInput.addEventListener('keyup', (e) => {
        let searchText = e.target.value.toLowerCase();
        filterProperties(searchText);
    });

    document.addEventListener('click', event => {
        const isClickInside = searchInput.contains(event.target);
        if (!isClickInside) {
            if(searchInput.value.length == 0) {
                document.querySelector('#search-input').classList.remove('expand');
            }
        }
        else {
            document.querySelector('#search-input').classList.add('expand');
        }
    })
    
    // Property category click event
    let selectOptions = document.querySelector('.property-filter .select-input .select-options');
    selectOptions.querySelectorAll('a').forEach((item) => {
        item.addEventListener('click', (e) => {
            e.preventDefault();
            let slug = item.getAttribute('data-slug');
            if(window.innerWidth >= 900) {
                getPropertiesTile(slug);    
            }
            else {
                filterProperties('');
            }
        });
    });
}

function getPropertiesTile(type) {
    if((!document.querySelector('.properties').classList.contains('list-display')) && !(document.querySelector('.properties').classList.contains('map-display'))) {
        document.querySelector('.properties-wrapper').style.transition = 'opacity 0.3s ease';
        document.querySelector('.properties-wrapper').style.opacity = 0;
        document.querySelector('.properties .loading-spinner').style.display = 'flex';
    }
    let data = new FormData();
    data.append('action', 'get_properties_tile');
    data.append('type', type);

    $.ajax({
        url: westco_scripts.ajax_url,
        type: 'POST',
        data: data,
        cache: false,
        processData: false,
        contentType: false,
        error: function (data) {
            console.error(data);
        },
        complete: function (data) {
            document.querySelector('.properties-wrapper .tiles').innerHTML = '';
            document.querySelector('.properties-wrapper .tiles').innerHTML = data.responseText;
            propertyEvents();
    
            let images = document.querySelectorAll('img.lazy');
            intersectionObserve(images, 0, 0, (image, intersectionRatio) => {
                (function(el) {
                    if (intersectionRatio > 0) {
                        if (el.classList && el.classList.contains('loaded')) {
                            return;
                        }
                        el.onload = function() {
                            if(!el.classList.contains('show')) {
                                el.classList.add('show');
                            }
                        }
                        el.src = el.getAttribute('data-src');
                        el.classList.add('loaded');  
                    } 
                })(image);
            });
            document.querySelector('.properties-wrapper').style.opacity = 1;
            document.querySelector('.properties .loading-spinner').style.display = 'none';
        }
    });
}