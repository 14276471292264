/*
 * Calculate header height.

 * In CSS, use the following to get the height:
 *   height: var(--headerHeight);
 */
const setupHeaderHeight = () => {
    let header = document.querySelector('header');
    if(header) {
        setTimeout(function() {
            let hh = document.querySelector('header').offsetHeight;
            document.documentElement.style.setProperty('--headerHeight', `${hh}px`);
        }, 100);
    }
}
setupHeaderHeight();
window.addEventListener('resize', setupHeaderHeight);
