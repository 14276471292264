const markerIconMobile = '<svg width="20" height="31" viewBox="0 0 20 31" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20 10C20 15.5228 10 30.2834 10 30.2834C10 30.2834 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10Z" fill="#D92632"/></svg>';
const markerIcon = '<svg width="40" height="61" viewBox="0 0 40 61" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M40 20C40 31.0457 20 60.5667 20 60.5667C20 60.5667 0 31.0457 0 20C0 8.9543 8.9543 0 20 0C31.0457 0 40 8.9543 40 20Z" fill="#D92632"/></svg>';
const markerVacantIconMobile = '<svg width="20" height="31" viewBox="0 0 20 31" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20 10C20 15.5228 10 30.2834 10 30.2834C10 30.2834 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10Z" fill="#D92632"/><circle cx="10" cy="9.50011" r="5" fill="white"/></svg>';
const markerVacantIcon = '<svg width="40" height="61" viewBox="0 0 40 61" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M40 20C40 31.0457 20 60.5667 20 60.5667C20 60.5667 0 31.0457 0 20C0 8.9543 8.9543 0 20 0C31.0457 0 40 8.9543 40 20Z" fill="#D92632"/><circle cx="20" cy="19" r="10" fill="white"/></svg>';

var styles = [
    {
        "featureType": "administrative",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "administrative",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#444444"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "all",
        "stylers": [
            {
                "color": "#f3ecdb"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "all",
        "stylers": [
            {
                "saturation": -100
            },
            {
                "lightness": 45
            },
            {
                "color": "#191c1f"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#191c1f"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "color": "#d92632"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "labels.text",
        "stylers": [
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "color": "#f3ecdb"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "simplified"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "all",
        "stylers": [
            {
                "color": "#ffffff"
            },
            {
                "visibility": "on"
            }
        ]
    }
];

let markersArray = [];

export function initMap() {
    if (!google || !google.maps) {
        return;
    }    
    var maps = document.querySelectorAll('.map');
    let infoPopup, infowindow;

    for (var i = 0; i < maps.length; i++) {
        var mapContainer = maps[i];
        if(mapContainer.classList.contains('initialized')){
            return;
        }
        mapContainer.classList.add('initialized');
        var locations = maps[i].querySelectorAll('.marker');
        var lat = parseFloat(locations[0].getAttribute('data-lat'));
        var lng = parseFloat(locations[0].getAttribute('data-lng'));
        infoPopup = document.querySelector('section.properties .property-modal');

        var position = {
            lat: lat,
            lng: lng
        };

        var options = {
            center: position,
            zoom: 15,
            maxZoom: 16,
            styles: styles,
            streetViewControl: false,
            mapTypeControl: false,
            scrollwheel: false,
            fullscreenControl: false,
        };

        var map = new google.maps.Map(mapContainer, options);

        infowindow =  new google.maps.InfoWindow({
            content: '',
        });

        var marker, j;

        if (locations.length == 1) {
            let icon;
            if(locations[0].getAttribute('data-vacant') == 'true') {
                if(window.innerWidth < 600) {
                    icon = markerVacantIconMobile;
                }
                else {
                    icon = markerVacantIcon;
                }
            }
            else {
                if(window.innerWidth < 600) {
                    icon = markerIconMobile;
                }
                else {
                    icon = markerIcon;
                }
            }
            const url = 'data:image/svg+xml;charset=UTF-8;base64,' + btoa(icon);
            var marker = new google.maps.Marker({
                position: position,
                map: map,
                icon: url
            });
            google.maps.event.addListener(marker, 'click', function () {
                window.open('https://maps.google.com/?q=' + locations[0].getAttribute('data-address'), '_blank');
            });
        }
        else {
            let icon;
            var propVacant, propAddress, propType, propTypes, propAreal, propLink, propImg, html, popupHtml;
            var bounds = new google.maps.LatLngBounds();
            for (j = 0; j < locations.length; j++) {
                if(locations[j].getAttribute('data-vacant') == 'true') {
                    if(window.innerWidth < 600) {
                        propVacant = '<div class="indicator-btn"><div id="circle"><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="60px" height="60px" viewBox="0 0 60 60" xml:space="preserve"><defs><path id="circlePath" d="M 30 30 m -15 0 a 15 15 0 0 1 30 0 a 15 15 0 0 1 -30 0 "/></defs><circle cx="30" cy="30" r="30" fill="none"/><g><use xlink:href="#circlePath" fill="none"/><text fill="#D92632"><textPath xlink:href="#circlePath">• ledig • ledig</textPath></text></g></svg></div></div>';
                        icon = markerVacantIconMobile;
                    }
                    else {
                        propVacant = '<div class="indicator-btn"><div id="circle"><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="100px" height="100px" viewBox="0 0 100 100" xml:space="preserve"><defs><path id="circlePathIndicator" d="M 50 50 m -30 0 a 30 30 0 0 1 60 0 a 30 30 0 0 1 -60 0 "/></defs><circle cx="50" cy="50" r="50" fill="none"/><g><use xlink:href="#circlePathIndicator" fill="none"/><text fill="#D92632"><textPath xlink:href="#circlePathIndicator">• ledig • ledig • ledig</textPath></text></g></svg></div></div>';
                        icon = markerVacantIcon;
                    }
                }
                else {
                    propVacant = '';
                    if(window.innerWidth < 600) {
                        icon = markerIconMobile;
                    }
                    else {
                        icon = markerIcon;
                    }
                }
                const url = 'data:image/svg+xml;charset=UTF-8;base64,' + btoa(icon);
                propAddress = locations[j].getAttribute('data-address').split(",")[0];
                propType = locations[j].getAttribute('data-type');
                propTypes = locations[j].getAttribute('data-types');
                propAreal = locations[j].getAttribute('data-areal');
                propLink = locations[j].getAttribute('data-link');
                propImg = locations[j].getAttribute('data-img');
                html = '<div class="prop-info">' + propVacant + '<div class="prop-img" style="background-image: url(' + propImg + ');"></div><div class="info-wrapper"><div class="address">' + propAddress + '</div><div class="meta-wrapper"><div class="type">' + propType + '</div><div class="areal">' + propAreal + 'm<span>2</span></div></div><a class="styled-btn" href="' + propLink + '" target="_blank">Se eiendom</a></div></div>';
                popupHtml = propVacant + '<div class="prop-img" style="background-image: url(' + propImg + ');"></div><div class="info-wrapper"><div class="address">' + propAddress + '</div><div class="meta-wrapper"><div class="type">' + propType + '</div><div class="areal">' + propAreal + 'm<span>2</span></div></div><a class="styled-btn" href="' + propLink + '" target="_blank">Se eiendom</a></div>';                ;
                marker = new google.maps.Marker({
                    position: new google.maps.LatLng(parseFloat(locations[j].getAttribute('data-lat')), parseFloat(locations[j].getAttribute('data-lng'))),
                    map: map,
                    icon: url,
                    label: {
                        text: " ",
                        className: propTypes,
                      },
                });
                var latlng = new google.maps.LatLng(parseFloat(locations[j].getAttribute('data-lat')), parseFloat(locations[j].getAttribute('data-lng')));
                markersArray.push(marker);
                bounds.extend(latlng);

                if(window.innerWidth >= 600) {
                    bindInfoWindow(marker, map, infowindow, html);
                }  
                else {
                    bindPopup(marker, popupHtml);
                }
            }
            filterMap();
        }
        function bindPopup(marker, html) { 
            google.maps.event.addListener(marker, 'click', function() {
                infoPopup.querySelector('.prop-info').innerHTML = html; 
                infoPopup.classList.add('show');
            });
        }
        function bindInfoWindow(marker, map, infowindow, html) { 
            google.maps.event.addListener(marker, 'click', function() {
                infowindow.setContent(html); 
                infowindow.open(map, marker);
            });
        } 
        if(infoPopup) {
            infoPopup.querySelector('#modal-close').addEventListener('click', (e) => {
                infoPopup.classList.remove('show');
            });
        }
    }
    const displayFilter = document.querySelector('header .display-filter');
    const selectOptions = document.querySelector('.property-filter .select-input .select-options');
    
    if(displayFilter) {
        displayFilter.querySelectorAll('a').forEach((item) => {
            item.addEventListener('click', (e) => {
                e.preventDefault();
                filterMap();
            });
        });
    }
    
    if(selectOptions) {
        selectOptions.querySelectorAll('a').forEach((item) => {
            item.addEventListener('click', (e) => {
                e.preventDefault();
                filterMap();
            });
        });
    }
    
    function filterMap() {
        infoPopup.classList.remove('show');
        infowindow.close();
        let selectedType = document.querySelector('.property-filter .select-input .select-options li.selected a').getAttribute('data-slug');
        let filteredBounds = new google.maps.LatLngBounds();
        let visibleCount = 0;
        if(selectedType == 'alle') {
            markersArray.forEach((item) => {
                item.setVisible(true);
                filteredBounds.extend(item.position);
                visibleCount = markersArray.length;
            });
        }
        else {
            markersArray.forEach((item) => {
                let types = item.getLabel().className.split(', ');
                if(types.includes(selectedType)) {
                    item.setVisible(true);
                    filteredBounds.extend(item.position);
                    visibleCount ++;
                }
                else {
                    item.setVisible(false);
                }
            });
        }
        if(visibleCount < 1) {
            markersArray.forEach((item) => {
                filteredBounds.extend(item.position);
                visibleCount = markersArray.length;
            });
        }
        map.fitBounds(filteredBounds);
    }
}