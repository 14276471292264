const timelineBlock = document.querySelector('section.timeline');

if(timelineBlock) {
    let wrapper, imageLineOffset, horizontalLineLength, height, quoteLineWidth = 0, imageLineWidth = 0;
    let blockOffsetTop = timelineBlock.offsetTop;
    if(window.innerWidth < 600) {
        wrapper = timelineBlock.querySelector('.wrapper.mobile');
    }
    else {
        wrapper = timelineBlock.querySelector('.wrapper.desktop');
    }
    let verticalLine = wrapper.querySelector('.line-elem');
    let circleTop = verticalLine.querySelectorAll('.circle')[0];
    let circleBottom = verticalLine.querySelectorAll('.circle')[1];
    let quoteLines = wrapper.querySelectorAll('.quote .horizontal-line');
    let images = wrapper.querySelectorAll('.image');
    let windowRel = (window.innerHeight / 1.25);
    
    if(window.innerWidth < 600) {
        horizontalLineLength = 3*16;
        imageLineOffset = 12;
    }
    else if(window.innerWidth < 1200) {
        horizontalLineLength = 6*16;
        imageLineOffset = (2*16) - 4;
    }
    else {
        horizontalLineLength = (window.innerWidth/12) * 1.5;
        imageLineOffset = 3*16;
    }

    window.addEventListener('scroll', function() {
        let scroll = window.scrollY;
        
        // Start circle
        if(scroll > (blockOffsetTop - windowRel - 8) && scroll < (blockOffsetTop - windowRel - 8) + window.innerHeight) {
            circleTop.style.cssText = `opacity: 1; transform: translate(-50%, -50%) scale(1);`;
        }
        else {
            circleTop.style.cssText = `opacity: 0; transform: translate(-50%, -50%) scale(0.5);`;
        }

        // Vertical line
        if(scroll > (blockOffsetTop - windowRel) && scroll < (timelineBlock.offsetHeight + blockOffsetTop - windowRel)) {
            height = scroll - (blockOffsetTop - windowRel);
            verticalLine.querySelector('.vertical-line').style.maxHeight = height + 'px';
        }
        else if(scroll < (blockOffsetTop - windowRel)) {
            verticalLine.querySelector('.vertical-line').style.maxHeight = 0 + 'px';
        }
        else {
            verticalLine.querySelector('.vertical-line').style.maxHeight = '100%';
        }


        // End circle
        if(scroll > (timelineBlock.offsetHeight + blockOffsetTop - windowRel - 8)) {
            circleBottom.style.cssText = `opacity: 1; transform: translate(-50%, 50%) scale(1);`;
        }
        else {
            circleBottom.style.cssText = `opacity: 0; transform: translate(-50%, 50%) scale(0.5);`;
        }

        // Quote lines
        quoteLines.forEach(function(line, index) {
            let threshold = (blockOffsetTop - windowRel + line.offsetTop + 16);
            if(scroll > threshold) {
                quoteLineWidth = (scroll - threshold) * 3;
                line.querySelector('.line').style.maxWidth = quoteLineWidth + 'px';
            }
            else if(scroll < threshold) {
                line.querySelector('.line').style.maxWidth = 0 + 'px';
            }
        });

        // Image lines
        images.forEach(function(image, index) {
            let imageLine = image.querySelector('.horizontal-line');
            let circle =  imageLine.querySelector('.circle');
            let parent = imageLine.parentNode;
            let offset = 0;
            let highImage = (image.querySelector('img').offsetHeight > window.innerHeight/2);
            if(highImage) {
                image.classList.add('high');
                offset = 96;
            }
            if(highImage && scroll > (blockOffsetTop - windowRel + parent.offsetTop + (parent.offsetHeight - (imageLineOffset + offset)))) {
                imageLineWidth = (scroll - (blockOffsetTop - windowRel + (parent.offsetTop + (parent.offsetHeight - (imageLineOffset + offset)))));
                imageLine.style.maxWidth = imageLineWidth + 'px';
                image.querySelector('img').style.cssText = `opacity: 1; transform: translateY(0);`;
                image.querySelector('p').style.cssText = `opacity: 1; transform: translateY(0);`;
            }
            else if(highImage) {
                imageLine.style.maxWidth = 0 + 'px';
                image.querySelector('img').style.cssText = `opacity: 0.7; transform: translateY(1rem);`;
                image.querySelector('p').style.cssText = `opacity: 0.7; transform: translateY(1rem);`;
            }

            if(!highImage && scroll > (blockOffsetTop - windowRel + parent.offsetTop + (parent.offsetHeight - imageLineOffset))) {
                imageLineWidth = (scroll - (blockOffsetTop - windowRel + (parent.offsetTop + (parent.offsetHeight - imageLineOffset))));
                imageLine.style.maxWidth = imageLineWidth + 'px';
                image.querySelector('img').style.cssText = `
                    opacity: 1;
                    transform: translateY(0);
                `;
                image.querySelector('p').style.cssText = `
                    opacity: 1;
                    transform: translateY(0);
                `;
            }
            else if(!highImage) {
                imageLine.style.maxWidth = 0 + 'px';
                image.querySelector('img').style.cssText = `opacity: 0.7; transform: translateY(1rem);`;
                image.querySelector('p').style.cssText = `opacity: 0.7; transform: translateY(1rem);`;
            }

            if(scroll > (blockOffsetTop - windowRel + (parent.offsetTop + (parent.offsetHeight - (imageLineOffset + offset))) + horizontalLineLength)) {
                circle.style.opacity = 1;
                circle.style.transform = 'scale(1)';
            }
            else {
                circle.style.opacity = 0;
                circle.style.transform = 'scale(0.5)';
            }
        });
    });
}