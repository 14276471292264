const properties = document.querySelectorAll('.related-properties .tease-property');

if(properties.length > 0 && window.innerWidth >= 900) {
    properties.forEach((item) => {
        let indicator = item.querySelector('.indicator-btn');
        let overlay = item.querySelector('.overlay');
        item.addEventListener('mousemove', (e) => {
            let rect = e.currentTarget.getBoundingClientRect();
            let x = e.clientX - rect.left; //x position within the element.
            let y = e.clientY - rect.top;  //y position within the element.
            if(indicator.classList.contains('show')) {
                indicator.style.top = y - indicator.offsetWidth/2 + 'px';
                indicator.style.left = x - indicator.offsetWidth/2 + 'px';
            }
            if(e.clientX  < rect.left || e.clientX  > (rect.left + e.currentTarget.offsetWidth) || e.clientY < rect.top || (e.clientY > rect.top + e.currentTarget.offsetHeight)) {
                indicator.classList.remove('show');
            }
            else {
                indicator.classList.add('show');
            }
        });
        item.addEventListener('mouseenter', (e) => {
            overlay.classList.add('show');
            indicator.classList.add('show');
        });
        item.addEventListener('mouseleave', (e) => {
            overlay.classList.remove('show');
            indicator.classList.remove('show');
        });
    });
}